import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../../utils/media-query/responsive.util';
import { colorsV2 } from '../../../style/colors-v2';
import { Typography } from '../../DesignSystemV2/Typography';
import { AspectRatio } from '../../AspectRatio/AspectRatio';
import { LazyImage } from '../../LazyImage';

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 4px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${colorsV2.black100};

    &:hover {
      text-decoration: underline;
    }
  }

  ${fromScreen(1144)} {
    .title {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  background-color: ${colorsV2.black20};
  border-radius: 8px;
  overflow: hidden;

  .news-thumbnail {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LinkContainer = styled.a`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  align-items: flex-start;
  row-gap: 12px;
`;

export const DolNewsCardFeature = props => {
  const { url, thumbnail, date, title, alt } = props;

  return (
    <LinkContainer href={url}>
      <AspectRatio className="image">
        <ImageContainer>
          <LazyImage
            className="news-thumbnail"
            imgProps={{
              alt: alt || title
            }}
            src={thumbnail}
          />
        </ImageContainer>
      </AspectRatio>
      <TextContainer>
        <Typography
          className="date"
          variant="regular/14-20"
          color={colorsV2.gray100}
        >
          {date}
        </Typography>
        <h3 className="title">{title}</h3>
      </TextContainer>
    </LinkContainer>
  );
};

DolNewsCardFeature.propTypes = {
  url: PropTypes.string,
  thumbnail: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string
};
