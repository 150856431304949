import { css } from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { fromScreen } from '../../utils/media-query/responsive.util';

export const headingStyles = css`
  .section-heading {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${colorsV2.black100};
    margin: 0;
  }

  ${fromScreen(458)} {
    .section-heading {
      font-size: 32px;
      line-height: 40px;
    }
  }

  ${fromScreen(1144)} {
    .section-heading {
      font-size: 40px;
      line-height: 48px;
    }
  }
`;
