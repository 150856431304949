import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { SITE_URL } from '../../apis/baseURL';
import { colorsV2 } from '../../style/colors-v2';
import { MONTH_NAME_FORMAT } from '../../constant';
import { DividerLineDivider } from '../Divider/LineDivider/DividerLineDivider';
import { DolNewsCardFeatureHighlight } from './Card/DolNewsCardFeatureHighlight';
import { DolNewsCardFeature } from './Card/DolNewsCardFeature';

const ListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(180px, 1fr));
  gap: 16px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  ${fromScreen(776)} {
    grid-template-columns: repeat(4, minmax(166px, 1fr));
    overflow-x: initial;
  }

  ${fromScreen(1144)} {
    gap: 24px;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 16px;

  ${fromScreen(458)} {
    row-gap: 24px;
  }

  ${fromScreen(1144)} {
    row-gap: 32px;
  }
`;

const getPageUrl = url => {
  if (url) {
    return `${SITE_URL}/${url}`;
  }
  return null;
};

export const DolNewsFeatureList = props => {
  const { data } = props;
  const highlightFeature = data?.[0] || {};
  const features = data?.slice(1);

  return (
    <Container>
      <DolNewsCardFeatureHighlight
        url={getPageUrl(highlightFeature.page?.url)}
        thumbnail={highlightFeature.cover?.url}
        alt={highlightFeature.cover?.alt}
        date={moment(highlightFeature.createdAt).format(MONTH_NAME_FORMAT)}
        title={highlightFeature.name}
        description={highlightFeature.description}
      />
      <DividerLineDivider
        direction="horizontal"
        size={1}
        color={colorsV2.gray20}
      />
      <ListContainer>
        {features?.map((feature, index) => {
          const { cover, createdAt, name, page } = feature;
          return (
            <DolNewsCardFeature
              key={index}
              url={getPageUrl(page?.url)}
              thumbnail={cover?.url}
              date={moment(createdAt).format(MONTH_NAME_FORMAT)}
              title={name}
              alt={cover?.alt}
            />
          );
        })}
      </ListContainer>
    </Container>
  );
};

DolNewsFeatureList.propTypes = {
  data: PropTypes.array
};
