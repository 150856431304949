import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../../utils/media-query/responsive.util';
import { colorsV2 } from '../../../style/colors-v2';
import { Typography } from '../../DesignSystemV2/Typography';
import { AspectRatio } from '../../AspectRatio/AspectRatio';

const TextContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 4px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
    color: ${colorsV2.black100};

    &:hover {
      text-decoration: underline;
    }
  }

  .description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    color: ${colorsV2.black80};
  }

  ${fromScreen(1144)} {
    row-gap: 8px;

    .title {
      font-size: 24px;
      line-height: 32px;
    }

    .description {
      -webkit-line-clamp: 5;
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  background-color: ${colorsV2.black20};
  border-radius: 8px;
  overflow: hidden;

  .news-thumbnail {
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const LinkContainer = styled.a`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 12px;

  ${fromScreen(776)} {
    grid-auto-flow: column;
    grid-template-columns: 292px minmax(0, 1fr);
    align-items: center;
  }

  ${fromScreen(1144)} {
    grid-auto-flow: column;
    grid-template-columns: 440px minmax(0, 1fr);
    align-items: center;
    gap: 24px;
  }
`;

export const DolNewsCardFeatureHighlight = props => {
  const { thumbnail, date, title, description, url, alt } = props;

  return (
    <LinkContainer href={url}>
      <AspectRatio className="image">
        <ImageContainer>
          <img
            className="news-thumbnail"
            alt={alt || title}
            fetchPriority="high"
            src={thumbnail}
          />
        </ImageContainer>
      </AspectRatio>
      <TextContainer>
        <Typography
          className="date"
          variant="regular/14-20"
          color={colorsV2.gray100}
        >
          {date}
        </Typography>
        <h3 className="title">{title}</h3>
        <p className="description">{description}</p>
      </TextContainer>
    </LinkContainer>
  );
};

DolNewsCardFeatureHighlight.propTypes = {
  url: PropTypes.string,
  thumbnail: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};
