import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { headingStyles } from './sectionStyles';

const Container = styled.section`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 24px;

  ${headingStyles}

  ${fromScreen(458)} {
    row-gap: 40px;
  }
`;

export const DolNewsSection = props => {
  const { title, content } = props;

  return (
    <Container>
      <h2 className="section-heading">{title}</h2>
      {content}
    </Container>
  );
};

DolNewsSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node
};
