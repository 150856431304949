import { css } from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';
import { fromScreen } from '../../utils/media-query/responsive.util';

export const headingStyles = css`
  .heading {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: ${colorsV2.black100};
    margin: 0;

    .highlight {
      position: relative;
      z-index: 1;

      &::after {
        content: '';
        position: absolute;
        background: ${colorsV2.yellow100};
        top: 26px;
        left: 0;
        height: 12px;
        width: 100%;
        opacity: 0.35;
        z-index: -1;
        bottom: 3px;
      }
    }
  }

  .description {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${colorsV2.black80};
  }

  ${fromScreen(458)} {
    .heading {
      font-size: 40px;
      line-height: 48px;

      .highlight {
        &::after {
          top: 32px;
        }
      }
    }
  }

  ${fromScreen(1144)} {
    .heading {
      font-size: 48px;
      line-height: 58px;

      .highlight {
        &::after {
          top: 39px;
        }
      }
    }

    .description {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
