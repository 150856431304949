import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { colorsV2 } from '../../style/colors-v2';

const ContentLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 64px;

  margin: 0 auto;

  ${fromScreen(458)} {
    max-width: 425px;
  }

  ${fromScreen(776)} {
    row-gap: 100px;
    max-width: 712px;
  }

  ${fromScreen(1144)} {
    max-width: 1080px;
  }
`;

const Content = styled.div`
  padding: 32px 16px;
  background-color: ${colorsV2.white100};

  ${fromScreen(458)} {
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    padding: 100px 0;
  }
`;

export const DolNewsMainContent = props => {
  const { sectionFeature, sectionLatest } = props;

  return (
    <Content>
      <ContentLayout>
        {sectionFeature}
        {sectionLatest}
      </ContentLayout>
    </Content>
  );
};

DolNewsMainContent.propTypes = {
  sectionFeature: PropTypes.node,
  sectionLatest: PropTypes.node
};
