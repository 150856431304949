import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { SITE_URL } from '../../apis/baseURL';
import { MONTH_NAME_FORMAT } from '../../constant';
import { DolNewsCard } from './Card/DolNewsCard';

const Action = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;

  ${fromScreen(776)} {
    grid-template-columns: 344px;
  }
`;

const ListLayout = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 24px;

  ${fromScreen(375)} {
    grid-template-columns: repeat(auto-fill, minmax(344px, 1fr));
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 40px;
`;

export const DolNewsLatestList = props => {
  const { data, loadMoreButton } = props;

  return (
    <Container>
      <ListLayout>
        {data?.map((item, index) => {
          const { cover, createdAt, name, description, page } = item;
          return (
            <DolNewsCard
              key={index}
              url={`${SITE_URL}/${page?.url}`}
              thumbnail={cover?.url}
              date={moment(createdAt).format(MONTH_NAME_FORMAT)}
              title={name}
              description={description}
              alt={cover?.alt}
            />
          );
        })}
      </ListLayout>
      <Action>{loadMoreButton}</Action>
    </Container>
  );
};

DolNewsLatestList.propTypes = {
  data: PropTypes.array,
  loadMoreButton: PropTypes.node
};
