import * as React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { fromScreen } from '../../utils/media-query/responsive.util';
import { colorsV2 } from '../../style/colors-v2';
import { headingStyles } from './bannerStyles';
import { Sketch1 } from '../Sketch/Sketch1';
import { Sketch4 } from '../Sketch/Sketch4';
import { Sketch5 } from '../Sketch/Sketch5';

const Layout = styled.div`
  position: relative;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content 1fr;
  align-items: flex-start;
  row-gap: 20px;

  margin: 0 auto;
  text-align: left;

  .banner-icon {
    display: none;
  }

  ${fromScreen(458)} {
    max-width: 425px;
    min-height: 180px;
  }

  ${fromScreen(776)} {
    max-width: 500px;
    text-align: center;

    .banner-icon {
      display: block;
    }

    .star-icon {
      position: absolute;
      width: 28px;
      height: 28px;
      bottom: calc(100% + 4px);
      right: 55px;
    }

    .spinning-arrow-icon {
      position: absolute;
      width: 59.06px;
      height: 24.23px;
      top: calc(100% + 8px);
      right: calc(100% + 10px);
      transform: matrix(-0.81, 0.56, -0.6, -0.82, 0, 0);
    }

    .wave-icon {
      position: absolute;
      width: 64px;
      height: 12px;
      top: calc(100% - 4px);
      left: calc(100% + 7px);
    }
  }

  ${fromScreen(1144)} {
    max-width: 662px;

    .star-icon {
      right: 79px;
    }

    .spinning-arrow-icon {
      top: calc(100% + 6px);
      right: calc(100% + 34px);
    }

    .wave-icon {
      top: 131px;
      left: calc(100% + 94px);
    }
  }
`;

const Container = styled.div`
  padding: 32px 16px;
  background-color: ${colorsV2.paper};

  ${headingStyles}

  ${fromScreen(458)} {
    padding: 32px 0;
  }

  ${fromScreen(776)} {
    padding: 100px 0;
  }
`;

export const DolNewsBanner = props => {
  const { title, description } = props;

  return (
    <Container className="dol-news-banner">
      <Layout>
        <h1 className="heading" dangerouslySetInnerHTML={{ __html: title }} />
        <p className="description">{description}</p>
        <Sketch1 className="banner-icon star-icon" />
        <Sketch4 className="banner-icon spinning-arrow-icon" />
        <Sketch5 className="banner-icon wave-icon" />
      </Layout>
    </Container>
  );
};

DolNewsBanner.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
